import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  signal,
  viewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InvisibleReCaptchaComponent, NgxCaptchaModule } from 'ngx-captcha';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ContactUsService } from 'src/app/shared/services/contact-us.service';
import { environment } from 'src/environments/environment';
enum SubmissionStatus {
  pending,
  success,
  error,
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'app-connect-form',
    templateUrl: './connect-form.component.html',
    styleUrls: ['./connect-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, NgxCaptchaModule]
})
export class ConnectFormComponent implements OnInit {
  contactUsFormGroup: UntypedFormGroup;
  showCaptcha = signal(false);
  submissionStatus = SubmissionStatus;
  status = signal(SubmissionStatus.pending);
  siteKey = environment.reCaptchaKey;
  private captchaRef = viewChild<InvisibleReCaptchaComponent>('captchaRef');
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private contactUsService: ContactUsService,
    private formBuilder: UntypedFormBuilder,
    private analyticService: AnalyticsService,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.showCaptcha.set(true);
    }
  }

  ngOnInit(): void {
    this.contactUsFormGroup = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      companyName: [
        '',
        [Validators.nullValidator, Validators.pattern('^.*?[^0-9].*$')],
      ],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.nullValidator]],
      message: ['', [Validators.required]],
      captcha: ['', [Validators.nullValidator]],
    });
  }

  onSave() {
    if (this.contactUsFormGroup.valid) {
      this.captchaRef()?.execute();
    } else {
      Object.keys(this.contactUsFormGroup.controls).forEach((field) => {
        const control = this.contactUsFormGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  // On click submit
  handleSuccess(captchaResponse: string) {
    if (this.contactUsFormGroup.valid) {
      this.contactUsFormGroup.controls.captcha.setValue(captchaResponse);
      const data: string = JSON.stringify(this.contactUsFormGroup.value);
      this.analyticService.logEvents('contact_form_submitted', {
        contact_form_value: data,
      });
      this.subscriptions.push(
        this.contactUsService
          .sendMail(this.contactUsFormGroup.value)
          .pipe(
            tap(() => {
              this.status.set(SubmissionStatus.success);
            }),
            catchError((error) => {
              this.status.set(SubmissionStatus.error);
              throw error;
            }),
          )
          .subscribe(),
      );
    } else {
      Object.keys(this.contactUsFormGroup.controls).forEach((field) => {
        const control = this.contactUsFormGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  reSet() {
    this.contactUsFormGroup.reset();
    this.status.set(SubmissionStatus.pending);
  }
  reTry() {
    this.status.set(SubmissionStatus.pending);
  }

  // text focus set in firebase analytics
  onFocus(field: string) {
    if (!this.contactUsFormGroup.controls[field].touched) {
      this.analyticService.logEvents('contact_form_field_touched', {
        contact_form_fieldName: field,
      });
    }
  }
}
