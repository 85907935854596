import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Contact } from '..';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  private url = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  sendMail(contactUsDetail: Contact) {
    const url = this.url + '/contact-us/sendMail';
    return this.httpClient.post(url, contactUsDetail);
  }
}
