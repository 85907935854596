<!-- Contact Form -->
@if (status() === submissionStatus.pending) {
  <form
    id="contact__form"
    [formGroup]="contactUsFormGroup"
    (ngSubmit)="onSave()"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            name="firstName"
            placeholder="First Name*"
            formControlName="firstName"
            (focus)="onFocus('firstName')"
          />
          @if (
            contactUsFormGroup.get("firstName").hasError("required") &&
            (contactUsFormGroup.get("firstName").touched ||
              contactUsFormGroup.get("firstName").dirty)
          ) {
            <span class="error-msg">First Name is required.</span>
          }
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            name="lastName"
            placeholder="Last Name*"
            formControlName="lastName"
            (focus)="onFocus('lastName')"
          />
          @if (
            contactUsFormGroup.get("lastName").hasError("required") &&
            (contactUsFormGroup.get("lastName").touched ||
              contactUsFormGroup.get("lastName").dirty)
          ) {
            <span class="error-msg">Last Name is required.</span>
          }
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            name="companyName"
            placeholder="Company Name"
            formControlName="companyName"
            (focus)="onFocus('companyName')"
          />

          @if (
            contactUsFormGroup.get("companyName").hasError("pattern") &&
            (contactUsFormGroup.get("companyName").touched ||
              contactUsFormGroup.get("companyName").dirty)
          ) {
            <span class="error-msg">Enter Valid String.</span>
          }
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            name="email"
            placeholder="Email*"
            formControlName="email"
            (focus)="onFocus('email')"
          />
          @if (
            contactUsFormGroup.get("email").hasError("required") &&
            (contactUsFormGroup.get("email").touched ||
              contactUsFormGroup.get("email").dirty)
          ) {
            <span class="error-msg">Email is required.</span>
          } @else if (
            contactUsFormGroup.get("email").hasError("email") &&
            (contactUsFormGroup.get("email").touched ||
              contactUsFormGroup.get("email").dirty)
          ) {
            <span class="error-msg">Email is not valid.</span>
          }
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            name="phoneNumber"
            placeholder="Phone Number"
            formControlName="phoneNumber"
            (focus)="onFocus('phoneNumber')"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group textarea_form_group">
          <textarea
            rows="5"
            class="form-control"
            name="message"
            placeholder="Message*"
            formControlName="message"
            (focus)="onFocus('message')"
          ></textarea>
          @if (
            contactUsFormGroup.get("message").hasError("required") &&
            (contactUsFormGroup.get("message").touched ||
              contactUsFormGroup.get("message").dirty)
          ) {
            <span class="error-msg">Message is required.</span>
          }
        </div>
      </div>
      <p class="recaptcha-msg">
        This site is protected by reCAPTCHA and the Google
        <a
          class="recaptcha-link hover-underline-animation"
          href="https://policies.google.com/privacy"
          >Privacy Policy</a
        >
        and
        <a
          class="recaptcha-link hover-underline-animation"
          href="https://policies.google.com/terms"
          >Terms of Service</a
        >
        apply.
      </p>
      @if (showCaptcha()) {
        <ngx-invisible-recaptcha
          #captchaRef
          name="captcha"
          [siteKey]="siteKey"
          [useGlobalDomain]="false"
          (success)="handleSuccess($event)"
          formControlName="captcha"
        >
        </ngx-invisible-recaptcha>
      }

      <div class="col-12">
        <button class="btn btn-bordered active contact_btn">
          <span><i class="fa fa-paper-plane"></i></span> Send Message
        </button>
      </div>
    </div>
  </form>
}

@if (status() !== submissionStatus.pending) {
  <div class="row">
    <div class="col-md-3">
      @if (status() === submissionStatus.success) {
        <h5 class="success-msg">Thank you</h5>
        <p class="success-msg">We will get back to you as soon as possible.</p>
        <button class="btn btn-bordered active btn-back" (click)="reSet()">
          <span><i class="fa fa-chevron-left"></i></span> Back
        </button>
      } @else {
        <h5 class="error-msg">oops!</h5>
        <p class="error-msg">
          There was some problem with your request, lets try again
        </p>
        <button class="btn btn-bordered active btn-back" (click)="reTry()">
          <span><i class="fa fa-chevron-left"></i></span> Back
        </button>
      }
    </div>
  </div>
}
