import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IContent } from 'shared/models';
import { ScrollToElementDirective } from '../../directives/scrollToElement.directive';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, ScrollToElementDirective]
})
export class BannerComponent {
  component = input<string>();
  bannerSection = input.required<IContent>();
  scrollToButton = input<HTMLElement>();
  scrollToExplore = input<HTMLElement>();

  constructor() {}
}
